import React from 'react';
import { Button } from 'react-bootstrap';

function Index(props) {
  const { text = "", width = "100%", background = "#000", icon = false, iconName ,disabled = false,borderRadius=0,color="white",borderColor=null} = props;





  return (
    <Button style={{zIndex:20, width, background, fontWeight: 600, color, border: `1px solid ${borderColor?borderColor:background}`, outline: background,borderRadius}} disabled={disabled}>
      {icon ? <><i className={iconName} />&nbsp;&nbsp;{text}</> : text}
    </Button>
  )
}

export default Index
import React, { useState, useEffect, useRef } from "react";
import style from "./header.module.scss";
import logo from "../../uploads/images/logo.png";
import { Link, NavLink, useLocation } from "react-router-dom";
import Button from "../button";
// import { useSelector } from "react-redux";
import { Form, InputGroup } from "react-bootstrap";
// import HeaderCourseList from "../../pages/headerCourseList";
import Avatar from "../../uploads/images/avatar.png";
import { axios, api } from "../../services";
import Swal from "sweetalert2";

function Index() {
  const location = useLocation();
  const allCourses = useRef([]);
  const initialFetch = {
    isLoading: false,
    error: false,
    data: null,
  };

  const [toggle, setToggle] = useState(false);
  const [toggleDropDown, setToggleDropDown] = useState(false);
  const [searchBoxActive, setSearchBoxActive] = useState(false);
  const [searchResult, setSearchResult] = useState([]);
  const [fetchCourse, setFetchCourse] = useState(initialFetch);

  // check login
  const loginVal = localStorage.getItem("login")
    ? JSON.parse(localStorage.getItem("login"))
    : {};
  const login = () => {
    const result = Object.keys(loginVal).length > 0 ? true : false;
    return result;
  };

  // console.log("datat", loginVal?.["profile-pic"]);

  const onClickCloseToggle = () => {
    setToggle(false);
  };

  const handlerSetSearchBoxActive = () => {
    setSearchBoxActive(!searchBoxActive);
  };
  const searchInput = (e) => {
    const input = e.target.value.toLocaleLowerCase();
    if (input === "") {
      console.log("input is empty");
      setSearchResult([]);
    } else {
      const courses = allCourses.current.filter((course) => {
        const filterCourse = course.title.toLocaleLowerCase();
        return filterCourse.includes(input);
      });
      if (courses.length > 0) {
        setSearchResult(courses);
        // console.log("filter courses", courses);
      }
    }
  };

  const fetchData = async () => {
    try {
      setFetchCourse({ ...fetchCourse, isLoading: true });
      const result = await axios.get(api.HOME, { crossdomain: true });
      if (!result.data || result.data === null || result.data === undefined) {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Something went wrong! Try again.",
        });
      }
      setFetchCourse({
        ...fetchCourse,
        data: result.data.category,
        isLoading: false,
      });
      console.log("fetchCourse data=>", result.data.category);
    } catch (error) {
      console.log("Error", error);
      setFetchCourse({ ...fetchCourse, isLoading: false, error: true });
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "Something went wrong! Try again..",
      });
    }
  };

  const setAllCoursesList = () => {
    if (fetchCourse.data != null && fetchCourse.data.length > 0) {
      console.log("inside useeffect if"); //? pls don't uncomment it.
      const Courses_list = [];
      for (const category of fetchCourse.data) {
        for (const course of category.category) {
          Courses_list.push(course);
        }
      }
      console.log("courses", Courses_list);
      if (Courses_list.length > 0) {
        allCourses.current = Courses_list;
      }
    } else {
      console.log("inside useeffect else"); //? pls don't uncomment it.
    }
  };

  useEffect(() => {
    fetchData();
    if (fetchCourse.data) {
      setAllCoursesList();
    }
  }, []);

  console.log("all_courses", allCourses);

  return (
    <div className={style.container}>
      <div className={style.box}>
        <div className={style.box_top}>
          <div style={{ flex: 1 }}></div>
          <div className={style.box_top_item}>
            <a
              href="mailto:info@rajendrasuryawanshi.com"
              style={{ color: "white", textDecoration: "none" }}
            >
              info@rajendrasuryawanshi.com
            </a>
          </div>
          <div className={style.box_top_item}>+91-8767265434</div>
        </div>
      </div>
      <div className={style.header}>
        <div className={style.logo_part}>
          <div></div>
          <div className={style.logo}>
            <Link to="/">
              <img src={logo} alt="logo" loading="lazy" />
            </Link>
          </div>
        </div>
        <div className={style.circle}></div>
        <div className={style.menu_part}>
          <div className={style.navbar}>
            <div className={style.navbar_items}>
              <NavLink
                to="/"
                className={style.navbar_item}
                style={{
                  color: location.pathname === "/" ? "#312883" : "black",
                }}
              >
                <i className="bi bi-house-door" />
                Home
              </NavLink>

              <NavLink
                to="courses"
                className={style.navbar_item}
                style={{
                  color: location.pathname === "/courses" ? "#312883" : "black",
                }}
              >
                <i
                  className="bi bi-journals"
                  style={{ paddingTop: 3, fontWeight: "bold" }}
                />
                Course
              </NavLink>
              <NavLink
                to="blog"
                className={style.navbar_item}
                style={{
                  color: location.pathname === "/blog" ? "#312883" : "black",
                }}
              >
                <i
                  className="bi bi-journal-bookmark"
                  style={{ paddingTop: 3, fontWeight: "bold" }}
                />
                Blogs
              </NavLink>
              <NavLink
                to="financial-calculator"
                className={style.navbar_item}
                style={{
                  color:
                    location.pathname === "/financial-calculator"
                      ? "#312883"
                      : "black",
                }}
              >
                <i
                  className="bi bi-calculator"
                  style={{ paddingTop: 3, fontWeight: "bold" }}
                />
                Financial Calculator
              </NavLink>
              <NavLink
                to="sebi"
                className={style.navbar_item}
                style={{
                  color: location.pathname === "/sebi" ? "#312883" : "black",
                }}
              >
                <i
                  className="bi bi-bar-chart"
                  style={{ paddingTop: 3, fontWeight: "bold" }}
                />
                SEBI
              </NavLink>
              <NavLink
                to="about-us"
                className={style.navbar_item}
                style={{
                  color:
                    location.pathname === "/about-us" ? "#312883" : "black",
                }}
              >
                <i className="bi bi-person" style={{ fontWeight: "bold" }} />
                About us
              </NavLink>
              <NavLink
                to="contact"
                className={style.navbar_item}
                style={{
                  color: location.pathname === "/contact" ? "#312883" : "black",
                }}
              >
                <i className="bi bi-phone" style={{ fontWeight: "bold" }} />
                Contact us
              </NavLink>
            </div>
            <div className={style.navbar_items}>
              {fetchCourse.error ? null : fetchCourse.isLoading ? null : (
                <div className={style.header_search}>
                  <div className={style.header_search_item}>
                    <InputGroup>
                      {searchBoxActive && (
                        <Form.Control
                          type="search"
                          placeholder="Search"
                          aria-label="Search"
                          onChange={(e) => searchInput(e)}
                        />
                      )}
                      <InputGroup.Text
                        id="basic-addon1"
                        onClick={() => handlerSetSearchBoxActive()}
                        style={{
                          color: "black",
                          background: "transparent",
                          outline: "none",
                          border: "none",
                        }}
                      >
                        <i className="bi bi-search" />
                      </InputGroup.Text>
                    </InputGroup>
                  </div>
                  <div className={style.header_search_result}>
                    {searchResult.length > 0 &&
                      searchBoxActive === true &&
                      searchResult.slice(0, 5).map((item, index) => (
                        <Link
                          to={`course/Advance%20Trading/${item.seo_title}`}
                          style={{ textDecoration: "none" }}
                          key={item}
                          onClick={() => {
                            setSearchResult([]);
                          }}
                        >
                          <p>{item.title}</p>
                        </Link>
                      ))}
                  </div>
                </div>
              )}
              {login() ? (
                loginVal.isSuperUser ? (
                  <NavLink to="/admin" className={style.navbar_item}>
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        gap: 5,
                      }}
                    >
                      <img
                        src={loginVal?.["profile-pic"]}
                        alt="name"
                        loading="lazy"
                        style={{
                          width: "25px",
                          height: "25px",
                          borderRadius: "50%",
                        }}
                      />
                      <p style={{ fontWeight: 600, margin: 0 }}>
                        {localStorage.getItem("login")
                          ? JSON.parse(localStorage.getItem("login")).name
                          : null}
                      </p>
                    </div>
                  </NavLink>
                ) : (
                  <NavLink to="/myaccount" className={style.navbar_item}>
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        gap: 5,
                      }}
                    >
                      <img
                        loading="lazy"
                        src={
                          localStorage.getItem("login")
                            ? JSON.parse(localStorage.getItem("login"))[
                                "profile-pic"
                              ]
                            : Avatar
                        }
                        alt="name"
                        style={{
                          width: "25px",
                          height: "25px",
                          borderRadius: "50%",
                        }}
                      />
                      <p style={{ fontWeight: 600, margin: 0 }}>
                        {localStorage.getItem("login")
                          ? JSON.parse(localStorage.getItem("login")).name
                          : null}
                      </p>
                    </div>
                  </NavLink>
                )
              ) : (
                <>
                  <NavLink to="login" className={style.navbar_item}>
                    <Button
                      text="Login"
                      background="#312883"
                      borderRadius="7px"
                      width="100px"
                      disabled={location.pathname === "/login" ? true : false}
                      style={{ width: "100px", borderRadius: 7 }}
                    />
                  </NavLink>
                  <NavLink to="sign-up" className={style.navbar_item}>
                    <Button
                      text="Sign Up"
                      background="transparent"
                      color="#312883"
                      borderColor="#312883"
                      borderRadius="7px"
                      width="100px"
                      disabled={location.pathname === "/sign-up" ? true : false}
                    />
                  </NavLink>
                </>
              )}
            </div>
          </div>
          {toggle && (
            <div className={style.navbar_active}>
              <hr />
              <NavLink
                to="/"
                className={style.navbar_active_item}
                style={{
                  background:
                    location.pathname === "/" ? "#312883" : "transparent",
                }}
                onClick={() => onClickCloseToggle()}
              >
                Home
              </NavLink>
              <Link to="/courses" style={{ textDecoration: "none" }}>
                <div
                  className={style.navbar_active_dropdown}
                  onClick={() => setToggleDropDown(!toggleDropDown)}
                  style={{
                    background:
                      location.pathname === "/courses"
                        ? "#312883"
                        : "transparent",
                    color: "white",
                  }}
                >
                  Courses
                </div>
              </Link>
              <NavLink
                to="/blog"
                className={style.navbar_active_item}
                style={{
                  background:
                    location.pathname === "/" ? "#312883" : "transparent",
                }}
                onClick={() => onClickCloseToggle()}
              >
                Blogs
              </NavLink>

              <NavLink
                to="financial-calculator"
                className={style.navbar_active_item}
                style={{
                  background:
                    location.pathname === "/financial-calculator"
                      ? "#312883"
                      : "transparent",
                }}
                onClick={() => onClickCloseToggle()}
              >
                Financial Calculator
              </NavLink>
              <NavLink
                to="sebi"
                className={style.navbar_active_item}
                style={{
                  background:
                    location.pathname === "/sebi" ? "#312883" : "transparent",
                }}
                onClick={() => onClickCloseToggle()}
              >
                SEBI
              </NavLink>
              <NavLink
                to="about-us"
                className={style.navbar_active_item}
                style={{
                  background:
                    location.pathname === "/contact"
                      ? "#312883"
                      : "transparent",
                }}
                onClick={() => onClickCloseToggle()}
              >
                About Us
              </NavLink>
              <NavLink
                to="contact"
                className={style.navbar_active_item}
                style={{
                  background:
                    location.pathname === "/contact"
                      ? "#312883"
                      : "transparent",
                }}
                onClick={() => onClickCloseToggle()}
              >
                Contact Us
              </NavLink>
              <InputGroup className="mb-2" style={{ padding: "8px 15px" }}>
                <InputGroup.Text id="basic-addon1">
                  <i className="bi bi-search" />
                </InputGroup.Text>
                <Form.Control
                  type="search"
                  placeholder="Search"
                  className="me-2"
                  aria-label="Search"
                />
              </InputGroup>
              {login() ? (
                <NavLink
                  to="/myaccount"
                  className={style.loginData}
                  onClick={() => onClickCloseToggle()}
                  style={{
                    textDecoration: "none",
                    color: "white",
                    padding: "0.5rem 0",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      gap: 10,
                    }}
                  >
                    <img
                      src={Avatar}
                      loading="lazy"
                      alt="name"
                      style={{
                        width: "25px",
                        height: "25px",
                        borderRadius: "50%",
                      }}
                    />
                    <p style={{ fontWeight: 600, margin: 0 }}>
                      {localStorage.getItem("login")
                        ? JSON.parse(localStorage.getItem("login")).name
                        : null}
                    </p>
                  </div>
                </NavLink>
              ) : (
                <>
                  <NavLink
                    to="login"
                    className={style.navbar_item}
                    style={{ margin: "10px 20px 10px" }}
                    onClick={() => onClickCloseToggle()}
                  >
                    <Button
                      text="Login"
                      background="#312883"
                      disabled={location.pathname === "/login" ? true : false}
                    />
                  </NavLink>
                  <NavLink
                    to="sign-up"
                    className={style.navbar_item}
                    style={{ margin: "0px 20px 10px" }}
                    onClick={() => onClickCloseToggle()}
                  >
                    <Button
                      text="Sign Up"
                      background="#312883"
                      disabled={location.pathname === "/sign-up" ? true : false}
                    />
                  </NavLink>
                </>
              )}
            </div>
          )}
          <div className={style.toggle} onClick={() => setToggle(!toggle)}>
            <i className="bi bi-list " />
          </div>
        </div>
      </div>
    </div>
  );
}

export default Index;

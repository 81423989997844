import React, { Suspense, lazy, useEffect } from "react";
import "./app.scss";
import { Routes, Route, Outlet, useLocation } from "react-router-dom";
// import { axios } from "../src/services/index";
import Loading from "./component/loading";
import PageNotFound from "./pages/pageNotFound";
// import Header from "./component/header";
import Header from "./component/header1";
// import { useState } from "react";

import Footer from "./component/footer";
import UserPrivateRoute from "./component/auth/privateRoute/userPrivateRoute";
import AdminPrivateRoute from "./component/auth/privateRoute/adminPrivateRoute";
// import Profile from "./pages/dashboard/Profile";
import { setHeaders } from "./helpers/requestHeaders";
import { useDispatch } from "react-redux";
import { setLogin } from "./slice/loginSlice";

const CagrCalculator = lazy(() => import("./pages/financialCalculator/calculator/CagrCalculator"));
const SipCalculator = lazy(() => import("./pages/financialCalculator/calculator/SipCalculator"));
const GoalCalculator = lazy(() => import("./pages/financialCalculator/calculator/GoalCalculator"));
const GoalsipCalculator = lazy(() => import("./pages/financialCalculator/calculator/GoalSipCalculator"));
const LumpsumCalculator = lazy(() => import("./pages/financialCalculator/calculator/LumpsumCalculator"));
const PositionSizingCalculator = lazy(() => import("./pages/financialCalculator/calculator/PositionSizingCalculator"));
const FAQ = lazy(() => import("./pages/faq"));
const GeneralFAQ = lazy(() => import("./pages/faq/GeneralFAQ"));
const PaymentFAQ = lazy(() => import("./pages/faq/PaymentFAQ"));
const CourseUpgradeFAQ = lazy(() => import("./pages/faq/CourseUpgradeFAQ"));
const AdmDashboard = lazy(() => import("./pages/adminDashboard/pages/dashboard"));
const Users = lazy(() => import("./pages/adminDashboard/pages/users"));
const OpenQuery = lazy(() => import("./pages/adminDashboard/pages/query/openQuery"));
const ClosedQuery = lazy(() => import("./pages/adminDashboard/pages/query/closedQuery"));
const Query = lazy(() => import("./pages/adminDashboard/pages/query"));
const QueryDetails = lazy(() => import("./pages/adminDashboard/pages/query/queryDetails"));

const Home = lazy(() => import("./pages/home"));
const Login = lazy(() => import("./component/auth/login"));
const SignUp = lazy(() => import("./component/auth/signUp"));
const SignUpVerify = lazy(() => import("./component/auth/signUp/otpVerify"));
const Courses = lazy(() => import("./pages/allCourses"));
const ForgotPassword = lazy(() => import("./component/auth/forgot"));
const ForgotPasswordVerify = lazy(() =>
  import("./component/auth/forgot/forgotVerify")
);
const About = lazy(() => import("./pages/about"));
const Contact = lazy(() => import("./pages/contact"));
const Course = lazy(() => import("./pages/courseDetails"));
const MasterCourses = lazy(() => import("./pages/masterCourseView"));
const Learn = lazy(() => import("./pages/learnView"));
const RefundPolicy = lazy(() => import("./pages/refundPolicy"));
const TermsAndConditions = lazy(() => import("./pages/termsAndConditions"));
const PrivacyPolicy = lazy(() => import("./pages/privacyPolicy"));
const GrievanceRedressal = lazy(() => import("./pages/grievanceRedressal"));


const Dashboard = lazy(() => import("./pages/dashboard1"));
const ProfileDetails = lazy(() => import("./pages/dashboard1/profileDetails"));
// const Profile = lazy(() => import("./pages/dashboard/accountInfo"));
// const MyOrder = lazy(() => import("./pages/dashboard/myOrder"));
// const MySubscription = lazy(() => import("./pages/dashboard/courseSubscription"));
// const AllMessage = lazy(() => import("./pages/dashboard/allMessage"));
// const CourseMessage = lazy(() => import("./pages/dashboard/courseMessage"));
// const NotFound = lazy(() => import("./pages/dashboard/notFound"));

const Cources = lazy(() => import("./pages/dashboard/Cources"));
const FinancialCalculator = lazy(() => import("./pages/financialCalculator"));
const Sebi = lazy(() => import("./pages/sebi"));
const Blog = lazy(() => import("./pages/blog"));
const BlogDetails = lazy(() => import("./pages/blogDetails"));
// const TestCard = lazy(() => import("./pages/testCard"));
const AdminDashboard = lazy(() => import("./pages/adminDashboard"));


function App() {
  const dispatch = useDispatch();
  const location = useLocation();

  useEffect(() => {
    setHeaders();
    const login = JSON.parse(localStorage.getItem("login"));
    if (login) {
      dispatch(setLogin(login));
    }
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location.pathname]);

  // const hideArr = ["/admin", "/qna-admin"];

  // const [chatbotOpen, setChatbotOpen] = useState(false);
  return (
    <div className="app">
      {/* {hideArr.filter((ele) => location.pathname.includes(ele)) ? null : (
        <Header />
      )} */}
      {location.pathname.includes("/admin") ||
        location.pathname.includes("/learn") ? null : (
        <Header />
      )}
      <div
        style={{
          background: "#F0F8FF",
          marginTop:
            location.pathname.includes("/admin") ||
              location.pathname.includes("/learn")
              ? "0px"
              : "100px",
        }}
      >
        <Suspense fallback={<Loading />}>
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/login" element={<Login />} />
            <Route path="/sign-up" element={<SignUp />} />
            <Route path="/sign-up/verify" element={<SignUpVerify />} />
            <Route path="/forgot-password" element={<ForgotPassword />} />
            <Route path="/faq" element={<Outlet />}>
              <Route index element={<FAQ />} />
              <Route path="general" element={<GeneralFAQ />} />
              <Route path="payment" element={<PaymentFAQ />} />
              <Route path="course-upgrade" element={<CourseUpgradeFAQ />} />
            </Route>
            <Route
              path="/forgot-password/verify"
              element={<ForgotPasswordVerify />}
            />
            <Route path="/contact" element={<Contact />} />
            <Route path="/courses" element={<Courses />} />
            <Route path="/about-us" element={<About />} />
            <Route path="/refund-policy" element={<RefundPolicy />} />
            <Route
              path="/terms-and-conditions"
              element={<TermsAndConditions />}
            />
            <Route path="/privacy-policy" element={<PrivacyPolicy />} />
            <Route path="/grievance-redressal-policy" element={<GrievanceRedressal />} />

            <Route
              path="/learn/:category/:id/:content_id"
              element={<Learn />}
            />

            <Route path="/course/:category/:course" element={<Course />} />
            <Route path="/m/:category/:course" element={<MasterCourses />} />
            <Route path="/financial-calculator" element={<Outlet />}>
              <Route index element={<FinancialCalculator />} />
              <Route path="cagr-calculator" element={<CagrCalculator />} />
              <Route path="sip-calculator" element={<SipCalculator />} />
              <Route path="goal-calculator" element={<GoalCalculator />} />
              <Route
                path="goal-sip-calculator"
                element={<GoalsipCalculator />}
              />
              <Route
                path="lumpsum-calculator"
                element={<LumpsumCalculator />}
              />
              <Route
                path="position-sizing-calculator"
                element={<PositionSizingCalculator />}
              />
            </Route>
            <Route path="/sebi" element={<Sebi />} />
            <Route path="/blog" element={<Blog />} />
            <Route path="/blog/:blogId" element={<BlogDetails />} />

            <Route element={<UserPrivateRoute />}>
              <Route path="/myaccount" element={<Dashboard />}>
                <Route index element={<ProfileDetails />} />
                <Route path="/myaccount/course" element={<ProfileDetails />} />
                <Route path="/myaccount/blog" element={<ProfileDetails />} />
              </Route>
            </Route>

            <Route element={<AdminPrivateRoute />}>
              <Route path="/admin" element={<AdminDashboard />}>
                <Route index element={<AdmDashboard />} />
                <Route path="users" element={<Users />} />
                <Route path="query" element={<Query />}>
                  <Route path="open" element={<OpenQuery />} />
                  <Route path="closed" element={<ClosedQuery />} />
                  <Route path="open/:id" element={<QueryDetails />} />
                </Route>
              </Route>
            </Route>
            {/* page not found */}
            <Route path="*" element={<PageNotFound />} />
          </Routes>
        </Suspense>
      </div>
      {location.pathname.includes("/admin") ? null : <Footer />}
      {/* {hideArr.filter((ele) => location.pathname.includes(ele)) ? null : (
        <Footer />
      )} */}
      {/*<div className="chatbot_container">
        <div className="chatbot_boxed">
          <i class="bi bi-wechat" style={{ fontSize: "5rem",cursor:"pointer" }} onClick={() => setChatbotOpen(!chatbotOpen)}></i>
          {chatbotOpen &&
            <div className="chatbot_box">
              <div className="chatbot_wapper">
                <div className="header">
                  <div className="header_left">
                    <img src="https://www.shutterstock.com/image-vector/chat-bot-logo-design-concept-260nw-1938811039.jpg" alt="chatbot" style={{ width: "2.5rem", height: "2.5rem", borderRadius: "100%" }} />
                    <div>
                      <p>chatbot</p>
                    </div>

                  </div>
                  <div>
                    <i class="bi bi-x-lg" onClick={() => setChatbotOpen(!chatbotOpen)} style={{cursor:"pointer"}}></i>
                  </div>
                </div>
                <hr />
                <div className="chat_box">
                  <div className="chatbox_input">
                    <input type="text" placeHolder="Typing somthing.." />
                    <div>
                      <p>
                        <i className="bi bi-paperclip"></i>
                      </p>
                      <p>
                        <i className="bi bi-send-fill"></i>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          }
        </div>
      </div>*/}
    </div>
  );
}

export default App;

import React from 'react'
import { Spinner } from 'react-bootstrap'

function Index(props) {

  return (
    <div style={{display:'flex',height:props.height?props.height:"100vh",width:"100%",alignItems:"center",justifyContent:"center"}}> <Spinner animation="border" variant="primary" /></div>
  )
}

export default Index
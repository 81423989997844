import React, { useEffect } from "react";

function Index() {
  useEffect(() => {
    document.title = "page not found";
  }, []);

  return <div>page not found</div>;
}

export default Index;

import React from "react";
import { useLocation, Navigate, Outlet } from "react-router-dom";

function Index() {
  const location = useLocation();
  const loginObject = localStorage.getItem("login")
    ? JSON.parse(localStorage.getItem("login"))
    : {};

  const superUser =
    Object.keys(loginObject).length > 0 ? loginObject.isSuperUser : false;

  return Object.keys(loginObject).length > 0 && superUser === false ? (
    <Outlet />
  ) : (
    <Navigate to="/login" state={{ from: location }} replace />
  );
}

export default Index;
